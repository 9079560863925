<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">                                                
        <v-card
            width="500"
            flat
            class="mt-3 pl-6 pr-3 mx-0"
        >
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="first_name"
                        v-model="record.first_name"
                        label="First Name"
                        hide-details="auto"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="last_name"
                        v-model="record.last_name"
                        label="Last Name"
                        hide-details="auto"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">                                            
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"                   
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="record.date_of_birth"
                            label="Date of Birth (Optional)"                                                    
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        >
                        </v-text-field>
                        </template>
                        <v-date-picker
                            ref="picker"
                            v-model="record.date_of_birth"                                                
                            @change="saveDate"                                                
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="birth_certificate_pin"
                        v-model="record.birth_certificate_pin"
                        label="Birth Certificate Pin"
                        hide-details="auto"
                        :rules="[rules.required]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-select
                        ref="gender"
                        v-model="record.gender"
                        :items="gender"
                        label="Gender (Optional)"
                        hide-details
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        ref="form_class_id"
                        v-model="record.form_class_id"
                        :items="formClasses"
                        item-text="id"
                        item-value="id"
                        label="Form Class"
                        hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="email"
                        v-model="record.email_address"
                        label="Email Address"
                        hide-details="auto"
                        :rules="[rules.email]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

        </v-card>                                                
    </v-tab-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created: function () {
        this.initialize();
    },
    
    props:{       
        
        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        },

        newStudent: {
            type: Boolean,
            default: false,
        },

        validate: {
            type: Boolean,
            default: false,
        }
    }, 

    watch: {       

        menu (val) {
            // console.log(this.$refs);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },        

        studentRecord: {
            handler () {
                this.initialize();
            }
        },

        validate: {
            handler (val) {
                if(val) this.validateForm();
            }
        }
    },

    data: () => ({
        gender: ['M', 'F'],  
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail.'
            }
        },     
        // rules: [
        //     value => !!value || 'Required'
        // ],
        menu: false,
        record: {
            student_id: null,
            first_name: null,
            last_name: null,
            other_name: null,
            date_of_birth: null,
            birth_certificate_pin: null,
            gender: null,
            form_class_id: null,
            serial_number: null,
            picture: null,
            email_address: null,
        },
        errorMessage: null,
        successMessage: null,
    }),

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent',
        })
    },

    methods: {
        ...mapActions({
            postStudent: 'students/postStudent',
            postClassRegistration: 'students/postClassRegistration',
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
            setClassRegistration: 'students/setClassRegistration',
        }),  
        
        initialize () {
            this.record = { ...this.studentRecord };
        },

        saveDate(date){
            this.$refs.menu.save(date);
        },

        validateForm () {
            let formHasErrors = false;
            this.setEditedStudent({
                birth_certificate_pin: this.record.birth_certificate_pin,
                date_of_birth: this.record.date_of_birth,
                first_name: this.record.first_name,
                form_class_id: this.record.form_class_id,
                gender: this.record.gender,
                other_name: this.record.other_name,
                id: this.record.student_id,                
                last_name: this.record.last_name,
                serial_number: this.studentRecord.serial_number,
                student_id: this.record.student_id,
                picture: this.studentRecord.picture,
                email_address: this.record.email_address
            });

            this.setClassRegistration({
                id: this.record.student_id,
                form_class_id: this.record.form_class_id
            })
            
            Object.keys(this.record).forEach( f => {
                if(
                    (
                        f === 'birth_certificate_pin' ||
                        f === 'first_name' ||
                        f === 'last_name'
                    ) &&
                    !this.record[f]
                ){
                    formHasErrors = true;
                    // console.log(this.$refs[f])
                    // this.$refs[f].validateForm(true);
                    this.$refs[f].validate(true);
                }                
            })
            
            if(formHasErrors){
                this.$emit('save-status', {type:'error', text: 'Please fill out the missing fields.'});
                this.setEditedStudent(this.record);
                return false;
            }
            this.update();
            
        },

        async update () {
            try {
                if(!this.record.student_id){
                    //new student
                    const { data: { student } } = await this.postStudent();
                    this.record.student_id = student.id;
                    this.setEditedStudent({...student});
                    this.$emit('enable-tabs');
                    this.$emit('update-students');
                    this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});
                    return;
                }
                
                const response = await this.postStudent();

                console.log('update response: ', response);

                if(this.record.form_class_id){
                    await this.postClassRegistration();
                } 


                this.$emit('save-status', {type:'success', text: 'Data successfully saved!'});

                this.$emit('update-students');

            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.$emit('save-status', {type:'error', text: 'Error saving data. Data not saved!'});
            }           
                     
        },
       
        resetFields () {
            Object.keys(this.fields).forEach(key => {
                key.saveProgress = false,
                key.saveError = false,
                key.saveSuccess = false
            })
        }
    }
}
</script>